@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

@layer base {
  @font-face {
    font-family: "GT Walsheim Pro";
    font-weight: normal;
    src: url('./assets/fonts/GT-Walsheim-Pro-Regular.otf');
    font-display: swap;
  }

  @font-face {
    font-family: "BalloonSCDExtBol W05 Regular";
    src: url("./assets/fonts/5210052/1abcddc2-508d-4d16-ade9-921e591ca0d6.woff2") format("woff2"), url("./assets/fonts/5210052/f076b2aa-2319-4935-91d0-b5321f753264.woff") format("woff");
    font-display: block;
  }

  @font-face {
    font-family: "Balloon Drop Shadow D W01 Rg";
    src: url("./assets/fonts/7620611/f59d128a-f0f8-4c01-afb1-418878c48440.woff2") format("woff2"), url("./assets/fonts/7620611/c89605ba-ee8e-47d3-93c1-9e71e13cc295.woff") format("woff");
    font-display: block;
  }

  @font-face {
    font-family: "GT Walsheim Pro";
    font-weight: bold;
    src: url('./assets/fonts/GT-Walsheim-Pro-Bold.otf');
    font-display: swap;
  }

  @font-face {
    font-family: "Blanch Caps";
    font-weight: 500;
    src: url('./assets/fonts/BLANCH_CAPS.otf');
    font-display: block;
  }
}

@layer utilities {
  .slanted-box-top {
    clip-path: polygon(0 25%, 100% 10%, 100% 90%, 0% 90%);
  }
  .slanted-full-box-top {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
    --webkit-clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%);
    margin-bottom: -1px;
    margin-left: -1px;
    margin-right: -1px;
  }
}

body {
  color: #231f20;
}

.mussila-logo {
  color: #F79725;
  text-shadow: 0.033em 0 0 #F05C57;
}

.remove-mussila-logo {
  color: black;
  text-shadow: 0 0;
}

.shadow-btm {
  --tw-shadow: 0 15px 0 0 rgba(0, 0, 0, 0.1), 0 -6px 0 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.-right-3\.25 {
  right: -0.800rem
    /* -13px */
  ;
}

.google-btn {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  width: 345px;
  height: 54px;
  background: #FFFFFF;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.fb-btn {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #FFF;
  width: 345px;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  background: #1877F2;
  border-radius: 10px;
}

.apple-btn {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  width: 345px;
  height: 54px;

  background: #000000;
  border-radius: 10px;
}

.left-0\.25 {
  left: 0.0625rem
    /* 1px */
     !important;
}

.top-0\.25 {
  left: 0.0625rem
    /* 1px */
     !important;
}

.text-as-shadow {
  text-shadow: 0.5px 1px 0px hsla(214, 89%, 52%, 0.6);
}

.shadow-box {
  box-shadow: 4px 4px 0px #B9B9B9;
}

.active\:shadow-box-none:active {
  box-shadow: 0 0 0 #B9B9B9;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  border-radius: 4px;  
  background: #DBDBDB;
  background-image: linear-gradient(#BEDBFF, #BEDBFF);
	background-repeat: no-repeat;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(#254266, #4173B2);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  appearance: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(#254266, #4173B2);
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

.slider::-webkit-slider-thumb:hover {
  box-shadow: #5DA5FF 0px 0px 0px 6px;
}

.slider::-webkit-slider-thumb:active {
  box-shadow: #5DA5FF 0px 0px 0px 8px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.input-without-arrows::-webkit-outer-spin-button,
.input-without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-without-arrows[type=number] {
  -moz-appearance: textfield;
}


/* @media screen and (orientation:portrait) {
  .games-container {
    --width: 100%;
  }
}

@media screen and (orientation:landscape) {
  .games-container {
    --height: 75%;
    --width: 100%;
  }
}

.games-container {
  width: var(--width);
  height: var(--width * 2);
} */

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. 
You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. 
Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
*/